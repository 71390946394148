import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Surface, Stack} from '@halp/ui';
import style from './Search.module.css';
import type {ReactNode} from 'react';

interface Props {
	value: string;
	onChange: (value: string) => void;
	children: ReactNode;
}

export function Search({value, onChange, children}: Props) {
	return (
		<Surface padding="xs" border="subdued" className={style.SearchContainer}>
			<Stack spacing="md">
				<Stack spacing="sm" alignItems="stretch" className={style.Search}>
					<FontAwesomeIcon icon={faMagnifyingGlass} color="var(--color-grey)" />
					<input
						onChange={(event) => onChange(event.target.value)}
						value={value}
						className={style.SearchInput}
					/>
				</Stack>
				<Stack alignItems="stretch" spacing="sm">
					{children}
				</Stack>
			</Stack>
		</Surface>
	);
}
